// Here you can add other styles

.sk-fold {
  width: 12px;
  height: 12px;
  margin: 4px;
  .sk-fold-cube::before {
    background-color: $secondary;
  }
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-active,
.c-dark-theme .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: $primary__dt;
}
