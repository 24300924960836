$theme-colors: (
  "primary": #fb6a5b,
  "secondary": #262b29,
  "purple": #710193
);

// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

.course-block-title {
  padding-bottom: 20px;
}

.green-text {
  color: #4CAF50;
}

.margin-left-right {
  margin-left: 2px;
  margin-right: 4px;
}

.underline-disable:hover {
  text-decoration: none;
}

.school-logo-container {
  display: flex;
  align-items: center;
  
  label {
    padding-top: 7px;
    padding-right: 10px;
  }

  img {
    height: 45px;
    margin-left: 10px;
  }
}

.school-logo-row {
  margin-top: 5px;
  margin-bottom: -10px;
}

.school-logo-row-admin {
  margin-bottom: -10px;
}